import * as Toggle from './_parts/toggle.js';

/**
 * ハンバーガー開閉に必要なセレクターのコンストラクタ
 */
class Selector {
  constructor({ ...targets }) {
    this.body = targets.body;
    this.header = targets.header;
    this.outer = targets.outer;
    this.button = targets.button;
    this.trigger = targets.trigger;
    this.box = targets.box;
    this.logo = targets.logo;
    this.triggerInners = targets.triggerInners;
    this.openingInners = targets.openingInners;
    this.arrowInners = targets.arrowInners;
  }
}

/**
 * ハンバーガー開閉に必要なセレクターを取得
 * @returns { object }
 */
function target() {
  const body = document.querySelector('body');
  const header = document.querySelector('.js-header');
  const outer = document.querySelector('.js-headerOuter');
  const button = document.querySelector('.js-headerButton');
  const trigger = document.querySelector('.js-hamburgerTrigger');
  const box = document.querySelector('.js-headerBox');
  const logo = document.getElementById('header_logo');
  const triggerInners = document.querySelectorAll('.js-hamburgerTriggerInner');
  const openingInners = document.querySelectorAll('.js-hamburgerElement');
  const arrowInners = document.querySelectorAll('.js-hamburgerArrow');

  return { body, header, outer, button, trigger, box, logo, triggerInners, openingInners, arrowInners };
}

/**
 * 初期値
 */
const selectors = new Selector({ ...target() });

/**
 * ハンバーガーの開閉
 */
export function hamburger() {
  selectors.trigger.addEventListener('click', () => {
    const isOpen = selectors.trigger.classList.contains('is-active');

    if (isOpen) {
      Toggle.close(selectors);
    } else {
      Toggle.open(selectors);
      Toggle.subHamburgerForceClose(selectors.openingInners, selectors.arrowInners);
    }
  });
}

/**
 * 下層のハンバーガーの開閉
 */
export function subHamburger() {
  const triggerInners = Array.prototype.slice.call(selectors.triggerInners, 0);

  triggerInners.forEach((triggerInner) => {
    triggerInner.addEventListener('click', () => {
      const isOpen = triggerInner.nextElementSibling.classList.contains('is-active');

      if (isOpen) {
        Toggle.closeInner(triggerInners);

        triggerInner.children[0].classList.remove('is-active');
        triggerInner.classList.remove('is-active');
      } else {
        Toggle.openInner(triggerInners);

        triggerInner.nextElementSibling.classList.add('is-active');
        triggerInner.children[0].classList.add('is-active');
      }
    });
  });
}
