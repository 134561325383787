/**
 * 下からフェードインする
 */
export function bottomToTop() {
  window.addEventListener('scroll', function () {
    const element = document.getElementsByClassName('js-anime');
    for (let i = 0; i < element.length; i++) {
      let rect = element[i].getBoundingClientRect();
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let myTop = rect.top + scrollTop;
      let elemPos = myTop - 50;
      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = document.documentElement.clientHeight;
      if (scroll >= elemPos - windowHeight) {
        element[i].classList.add('is-active');
      } else {
        element[i].classList.remove('is-active');
      }
    }
  });
}
