/**
 * ハンバーガーを閉じる
 */
export function close(target) {
  // bodyの固定
  target.body.classList.remove('is-fixed');

  // hamburger
  target.header.classList.remove('is-active');
  target.box.classList.remove('is-view');
  target.trigger.classList.remove('is-active');

  // corporate logo
  target.logo.classList.remove('blush');
}

/**
 * ハンバーガーを開く
 */
export function open(target) {
  // bodyの固定
  target.body.classList.add('is-fixed');

  // hamburger
  target.header.classList.add('is-active');
  target.box.classList.add('is-view');
  target.trigger.classList.add('is-active');

  // corporate logo
  target.logo.classList.add('blush');
}

/**
 * ハンバーガー下層を閉じる
 */
export function closeInner(target) {
  // 一旦、全てクラスを外す
  target.forEach((e) => {
    // ハンバーガー下層のボタンの修飾
    e.nextElementSibling.classList.remove('is-active');
  });
}

/**
 * ハンバーガー下層を開く
 */
export function openInner(target) {
  // 一旦、全てクラスを外す
  target.forEach((e) => {
    e.classList.remove('is-active');
    e.nextElementSibling.classList.remove('is-active');
    e.children[0].classList.remove('is-active');
  });
}

/**
 * ×を押した時に強制的にハンバーガー下層を閉じる
 * @param { object } openInners
 * @param { object } arrowInners
 */
export function subHamburgerForceClose(openInners, arrowInners) {
  const openInnerList = Array.prototype.slice.call(openInners, 0);
  const arrowInnerList = Array.prototype.slice.call(arrowInners, 0);

  openInnerList.forEach((openInner) => {
    openInner.classList.remove('is-active');
  });

  arrowInnerList.forEach((arrowInner) => {
    arrowInner.classList.remove('is-active');
  });
}
