import { bottomToTop } from './modules/animation';
import { hamburger, subHamburger } from './modules/hamburger';
import { pageTop } from './modules/pageTop';
import { smooth } from './modules/smooth';

bottomToTop();
hamburger();
subHamburger();
pageTop();
smooth();
